.modalContentWrap {
  display: flex;
  justify-content: center;
}

.providerName {
  text-transform: capitalize;
}

.requestMessage {
  text-align: center;
}

.modal {
  padding: 40px;
}
