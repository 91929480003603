.heatmap {
  box-sizing: border-box;
  display: grid;
  height: 38px;
  padding: 6px 12px;
  border-radius: 2px;
  gap: 1px;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    > p {
      font-size: 12px;
      margin: 0;
    }
  }

  &__block {
    height: 10px;
    width: 100%;
    background-color: var(--heatmap-color);
    opacity: var(--heatmap-opacity);
  }
}

.legend {
  display: flex;
  box-sizing: border-box;
  -moz-column-gap: 20px;
       column-gap: 20px;
  row-gap: 10px;
  flex-wrap: wrap;

  div {
    display: flex;
    justify-content: center;
  }

  .icon {
    margin-right: 6px;
  }

  .splitter {
    height: 10px;
    width: 1.33px;
    align-self: center;
    border-radius: 50%;
    background-color: #b2b2b2;
    margin: 0 10px;
  }
}
