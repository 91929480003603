.quickStartGuide {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 40px;
}

.cardsWrapper {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 0 20px;
  min-width: -moz-min-content;
  min-width: min-content;
  overflow-y: auto;

  & > div {
    flex: 1;
    margin-bottom: 20px;
    -moz-column-break-inside: avoid;
         break-inside: avoid;
    min-width: 483px;
  }
}

.tabs {
  position: fixed;
  width: 100vw;
  top: 55px;
  z-index: 99;
  padding: 24px 0 0 24px;
  background-color: #fff;
}

.loaderWrapper {
  width: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
}

.noDataWrapper {
  display: flex;
  justify-content: center;
}

.modalContentWrap {
  display: flex;
  justify-content: center;
}

.providerName {
  text-transform: capitalize;
}
