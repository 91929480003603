.pageWrapper {
  position: relative;
}

.flexEnd {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
}

.flexStart {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}

.search {
  margin-bottom: 1.25rem;
}

.pageHeaderRow {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 1.25rem;

  &Tabs {
    max-width: 50%;
    @media (max-width: 1009px) {
      max-width: 100%;
    }
  }
}

.content {
  display: grid;
  gap: 40px;

  @media (min-width: 1024px) {
    height: calc(100dvh - 193px);
  }

  &__showMap {
    @media (min-width: 1024px) {
      grid-template-columns: 7fr 5fr;
    }
  }

  > div:first-child {
    display: flex;
    flex-direction: column;
  }

  &__fullscreenMap {
    > div:first-child {
      > div:not([id='mapMobile']) {
        display: none;
      }
    }

    @media (min-width: 1024px) {
      grid-template-columns: 1fr;

      > div:first-child {
        display: none;
      }
    }
  }
}

.mapWrapperClassName {
  display: none;
  width: 100%;

  &_show {
    @media (min-width: 1024px) {
      display: block;
    }
  }

  &_fullscreen {
    @media (min-width: 1024px) {
      display: block;
      height: calc(100dvh - 200px);
    }
  }

  &__mobile {
    display: none;

    &_show {
      display: block;
      @media (min-width: 1024px) {
        display: none;
      }
    }

    &_fullscreen {
      height: calc(100dvh - 240px);
    }
  }
}

.mapContainerClassName {
  min-height: 500px;
  height: 100%;

  @media (min-width: 1024px) {
    height: 100%;
  }
}

.backButton {
  align-self: flex-start;
  margin-bottom: 1rem;
}

.cell {
  text-wrap: nowrap;
}

.activeCell {
  position: relative;
  left: 8px;
  color: white;
  background-color: var(--bgColor);
  padding: 1px 8px;
  opacity: 0.7;
  border-radius: 20px;
  text-align: right;

  &_disabled {
    opacity: 0.2;
  }
}

.badges {
  display: grid;
  place-content: flex-end;
  gap: 4px;
}

.pageHeaderRowTabs {
  margin-top: -8px;
}

.defaultCursor {
  label {
    span {
      cursor: default;
    }
  }
}

.viewsContainer {
  height: inherit;
  overflow: auto;
}

.chartContainer {
  margin-bottom: 1.875rem;
}

.tableDatesMode {
  tbody {
    tr {
      td {
        &::after {
          display: none;
        }
      }
    }
  }
}

.breadcrumbStyle {
  div:nth-child(2) {
    a {
      span:first-child {
        text-transform: capitalize;
      }
    }
  }
}
